import { hydrateWhenVisible } from 'vue-lazy-hydration'
import CartInfo from '@theme/components/cart/CartInfo'
import Container from '@theme/components/utils/Container'
import HeaderMainHook from '@theme/components/shop/HeaderMainHook'
import HeaderMainLogoHook from '@theme/components/shop/HeaderMainLogoHook'
import Logo from '@theme/components/molecule/Logo'

export default {
  components: {
    CartInfo,
    Container,
    CurrencySwitcher: hydrateWhenVisible(() => import('@theme/components/shop/CurrencySwitcher')),
    Dropdown: () => import('@theme/components/utils/Dropdown'),
    HeaderMainHook,
    HeaderMainLogoHook,
    HeaderSearch: () => import('@theme/components/shop/HeaderSearch'),
    LazyComponent: () => import('@theme/components/utils/LazyComponent'),
    Logo,
    SearchInput: () => import('@theme/components/shop/SearchInput'),
  },
  data() {
    return {
      LazyCartInfoDropDownContent: () => import('@theme/components/cart/CartInfoDropDownContent'),
      open: false,
      mainMenuMobileComponent: null,
      mainMenuMobileAriaControls: null,
    }
  },
  computed: {
    headerMainHookBreakpointVisibility() {
      return this.$themeSettings.components.HeaderMain.headerMainHookForceEnable.enable &&
        this.$themeSettings.components.HeaderMain.headerMainHookForceEnable.breakpoint
        ? this.$themeSettings.components.HeaderMain.headerMainHookForceEnable.breakpoint
        : this.$contentSettings.functions.compare || this.$contentSettings.functions.wishList
          ? 'xl'
          : 'lg'
    },
    userLink() {
      return this.$store.getters['user/userIsLoggedIn'] ? 'user' : 'login'
    },
    searchInputFocus() {
      return this.$store.state.search.headerSearchInputOpen
    },
    showTextInHamburgerMenu() {
      return this.$contentSettings.functions.compare || this.$contentSettings.functions.wishList
    },
  },
  methods: {
    async toggleMobileMenu() {
      const toggle = () => {
        this.$refs.MainMenuMobile.toggleMenu()
        this.open = !this.open
        this.$emit('changeOpen', this.open)
        this.mainMenuMobileAriaControls = 'MainMenuMobile'
      }
      if (!this.mainMenuMobileComponent) {
        this.mainMenuMobileComponent = (await import('@theme/components/shop/MainMenuMobile')).default
        this.$nextTick(() => {
          toggle()
        })
      } else {
        toggle()
      }
    },
    toggleSearch(e) {
      if (this.$themeSettings.components.HeaderMain.mobileSearchAutocomplete) {
        e.preventDefault()
        this.searchInputFocus
          ? this.$store.commit('search/CLOSE_HEADER_SEARCH_INPUT')
          : this.$store.commit('search/OPEN_HEADER_SEARCH_INPUT')
      }
    },
  },
}
